import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
import ThemeContext from '../context/ThemeContext'

const rootPath = `${__PATH_PREFIX__}/`

class Header extends React.Component {
    render() {
        const { location, title } = this.props
        if (location.pathname === rootPath) {
            return (
                <ThemeContext.Consumer>
                    {theme => (
                        <header>
                            <h1
                            style={{
                                ...scale(1.5),
                                marginBottom: rhythm(1.5),
                                marginTop: 0,
                            }}
                            >
                                <Link
                                    style={{
                                    boxShadow: `none`,
                                    textDecoration: `none`,
                                    color: `inherit`,
                                    }}
                                    to={`/`}
                                >
                                    {title}
                                </Link>
                            </h1>
                            <button className="dark-switcher" onClick={theme.toggleDark}>
                                {theme.dark ? <span>Light mode ☀</span> : <span>Dark mode ☾</span>}
                            </button>
                        </header>
                    )}
                </ThemeContext.Consumer>
            )
        } else {
            return (
                <ThemeContext.Consumer>
                    {theme => (
                        <header>
                            <h3
                            style={{
                                fontFamily: `Montserrat, sans-serif`,
                                marginTop: 0,
                            }}
                            >
                            <Link
                                style={{
                                boxShadow: `none`,
                                textDecoration: `none`,
                                color: `inherit`,
                                }}
                                to={`/`}
                            >
                                {title}
                            </Link>
                            </h3>
                            <button className="dark-switcher" onClick={theme.toggleDark}>
                                {theme.dark ? <span>Light mode ☀</span> : <span>Dark mode ☾</span>}
                            </button>
                        </header>
                    )}
                </ThemeContext.Consumer>
            )
        }
    }
}
    
export default Header
