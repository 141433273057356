import React from "react"

import ThemeContext from '../context/ThemeContext'
import Header from './header'
import { rhythm } from "../utils/typography"
import './layout.css'


class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    return (
      <ThemeContext.Consumer>
        {theme => (
        <div className={theme.dark ? 'dark' : 'light'}>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <Header location={location} title={title}/>
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>
        </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Layout
